

:root { // base :root keys and values must be set
    --tenant-50: #bfe0a2;
    --tenant-100: #b2d98e;
    --tenant-200: #a5d37b;
    --tenant-300: #98cd68;
    --tenant-400: #8bc655;
    --tenant-500: #7EC042; // Capillary default
    --tenant-600: #71ae3a;
    --tenant-700: #659b34;
    --tenant-800: #59882d;
    --tenant-900: #4c7527;
    --tenant-A100: #cce6b5;
    --tenant-A200: #d9edc8;
    --tenant-A400: #e6f3db;
    --tenant-A700: #406221;
    --tenant-50-contrast: rgba(0, 0, 0, 0.87);
    --tenant-100-contrast: rgba(0, 0, 0, 0.87);
    --tenant-200-contrast: rgba(0, 0, 0, 0.87);
    --tenant-300-contrast: rgba(0, 0, 0, 0.87);
    --tenant-400-contrast: rgba(0, 0, 0, 0.87);
    --tenant-500-contrast: rgba(0, 0, 0, 0.87); // Capillary default font WCAG AAA
    --tenant-600-contrast: #ffffff;
    --tenant-700-contrast: #ffffff;
    --tenant-800-contrast: #ffffff;
    --tenant-900-contrast: #ffffff;
    --tenant-A100-contrast: rgba(0, 0, 0, 0.87);
    --tenant-A200-contrast: rgba(0, 0, 0, 0.87);
    --tenant-A400-contrast: rgba(0, 0, 0, 0.87);
    --tenant-A700-contrast: #ffffff;

    --mat-transition-duration: 0.4s;
    --mat-transition-timing-function: cubic-bezier(.25, .8, .25, 1);

    --bs-border-radius: 0.25rem;
    --bs-border-color: #dee2e6;

    --allogy-mat-toolbar-height: 50px;
    --allogy-footer-height: 35px;
    --allogy-breadcrumb-height: 40px;
    --allogy-transition-duration: 0.4s;
    --allogy-transition-timing-function: cubic-bezier(.25, .8, .25, 1);
}

$tenant-map: (
    50: var(--tenant-50, #bfe0a2),
    100: var(--tenant-100, #b2d98e),
    200: var(--tenant-200, #a5d37b),
    300: var(--tenant-300, #98cd68),
    400: var(--tenant-400, #8bc655),
    500: var(--tenant-500, #7EC042),
    600: var(--tenant-600, #71ae3a),
    700: var(--tenant-700, #659b34),
    800: var(--tenant-800, #59882d),
    900: var(--tenant-900, #4c7527),
    A100: var(--tenant-A100, #cce6b5),
    A200: var(--tenant-A200, #d9edc8),
    A400: var(--tenant-A400, #e6f3db),
    A700: var(--tenant-A700, #406221),
    contrast:(
        50: var(--tenant-50-contrast, rgba(0, 0, 0, 0.87)),
        100: var(--tenant-100-contrast, rgba(0, 0, 0, 0.87)),
        200: var(--tenant-200-contrast, rgba(0, 0, 0, 0.87)),
        300: var(--tenant-300-contrast, rgba(0, 0, 0, 0.87)),
        400: var(--tenant-400-contrast, rgba(0, 0, 0, 0.87)),
        500: var(--tenant-500-contrast, rgba(0, 0, 0, 0.87)),
        600: var(--tenant-600-contrast, #ffffff),
        700: var(--tenant-700-contrast, #ffffff),
        800: var(--tenant-800-contrast, #ffffff),
        900: var(--tenant-900-contrast, #ffffff),
        A100: var(--tenant-A100-contrast, rgba(0, 0, 0, 0.87)),
        A200: var(--tenant-A200-contrast, rgba(0, 0, 0, 0.87)),
        A400: var(--tenant-A400-contrast, rgba(0, 0, 0, 0.87)),
        A700: var(--tenant-A700-contrast, #ffffff),
    )
);

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// // 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../node_modules/bootstrap/scss/functions";

// // 2. Include any default variable overrides here
$blue: mat-color($mat-blue, 700);
$indigo: mat-color($mat-indigo, 500);
$purple: mat-color($mat-purple, 500);
$pink: mat-color($mat-pink, 500);
$red: mat-color($mat-red, 500);
$orange: mat-color($mat-orange, 500);
$yellow: mat-color($mat-yellow, 500);
$green: mat-color($mat-green, 500);
$teal: mat-color($mat-teal, 500);
$cyan: mat-color($mat-cyan, 500);
$dark: $dark-primary-text;
$font-family-sans-serif: Roboto, "Helvetica Neue", sans-serif, "Segoe UI", Arial, "Noto Sans", "Liberation Sans", system-ui, -apple-system, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";


// // 3. Include remainder of required Bootstrap stylesheets
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

// Remove unwanted theme colors by name, https://getbootstrap.com/docs/5.0/customize/sass/#remove-from-map
$theme-colors: map-remove($theme-colors, "info", "warning", "danger");
// Create your own map, https://getbootstrap.com/docs/5.0/customize/sass/#add-to-map
$additional-material-themePalette-colors: ( "caution": $yellow, "warn": $red);
// Merge the maps
$theme-colors: map-merge($theme-colors, $additional-material-themePalette-colors);


// // 4. Include any optional Bootstrap components as you like
@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/bootstrap-reboot";
@import "../node_modules/bootstrap/scss/bootstrap-grid";
@import "../node_modules/bootstrap/scss/bootstrap-utilities";
@import "../node_modules/bootstrap/scss/type";

// 5. Add additional custom code here

// Overwrite material typography values with improved Bootstrap values
$custom-typography: mat-typography-config(
    $font-family:   $font-family-sans-serif,

    $display-4:     mat-typography-level(112px, 112px, $font-weight-light, $letter-spacing: -0.05em),
    $display-3:     mat-typography-level(56px, $line-height-lg, $font-weight-normal, $letter-spacing: -0.02em),
    $display-2:     mat-typography-level(45px, $line-height-lg, $font-weight-normal, $letter-spacing: -0.005em),
    $display-1:     mat-typography-level(34px, $line-height-lg, $font-weight-normal),

    $headline:      mat-typography-level($h1-font-size, $line-height-base, $font-weight-normal), // h1
    $title:         mat-typography-level($h1-font-size, 32px, 500), // h2
    $subheading-2:  mat-typography-level($h1-font-size, $line-height-base, $font-weight-normal), // h3
    $subheading-1:  mat-typography-level($h1-font-size, $line-height-base, $font-weight-normal), // h4

    $body-1:        mat-typography-level($font-size-base, $line-height-base, $font-size-base), // base
    $body-2:        mat-typography-level($font-size-base, $line-height-lg, 500), // base w space
    $caption:       mat-typography-level($font-size-sm, $line-height-sm, $font-size-base), // small
    $button:        mat-typography-level($font-size-base, $line-height-base, 500), // base w bold
    $input:         mat-typography-level(inherit, $line-height-base, $font-size-base) // base form
);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core($custom-typography);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

// The warn palette is optional (defaults to red).

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$website-theme: mat-light-theme((
    color: (
        primary: mat-palette($tenant-map),
        accent: mat-palette($mat-blue),
    )));

$operations-theme: mat-light-theme((
    color: (
        primary: mat-palette($mat-green),
        accent: mat-palette($mat-yellow),
    )));


// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($website-theme);

@mixin allogy-utility-color-classes() {

    $theme-colors: (
        "tenant": $tenant-map,
    );
    @each $color, $value in $theme-colors {
        .bg-#{$color} {
            background-color: mat-color($value) !important;
        }
        .bg-#{$color}-text {
            color: mat-color($value, default-contrast) !important;
        }
        .text-#{$color} {
            color: mat-color($value, darker) !important;
        }
        .border-#{$color} {
            border-color: mat-color($value) !important;
        }
        .badge-#{$color} {
            color: mat-color($value, default-contrast) !important;
            background-color: mat-color($value) !important;
        }
        .btn-#{$color} {
            color: mat-color($value, default-contrast) !important;
            background-color: mat-color($value) !important;
            border-color: mat-color($value) !important;
            &:hover,
            &:focus {
                color: mat-color($value, darker-contrast) !important;
                background-color: mat-color($value, darker) !important;
                border-color: mat-color($value, darker) !important;
            }
        }
    }

}

.operations-button {
  // This will only generate the color styles for `mat-button`.
  @include mat-button-color($website-theme);
}


/* You can add global styles to this file, and also import other style files */

html,
body {
    width: 100vw;
    height: 100vh;
    background-color: $gray-400;
    overflow-x: hidden;
}

body {
    margin: 0;
}

.mat-tooltip {
    font-size: 1rem;
}

.bitmoji.mat-button-base {
    .mat-button-wrapper{
        padding: 0;
    }
}
